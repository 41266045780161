import full01 from '../../../assets/images/fulls/SGRed.png'
import full02 from '../../../assets/images/fulls/IOBuilders.png'
import full03 from '../../../assets/images/fulls/MeteoAran.png'
import full04 from '../../../assets/images/fulls/BoatTrack.png'
import full05 from '../../../assets/images/fulls/BcnByBike.png'
import full06 from '../../../assets/images/fulls/JuliaVila.png'
import thumb02 from '../../../assets/images/thumbs/IOBuilders.png'
import thumb01 from '../../../assets/images/thumbs/SGRed.png'
import thumb03 from '../../../assets/images/thumbs/MeteoAran.png'
import thumb04 from '../../../assets/images/thumbs/BoatTrack.png'
import thumb05 from '../../../assets/images/thumbs/BcnByBike.png'
import thumb06 from '../../../assets/images/thumbs/JuliaVila.png'


export const DEFAULT_IMAGES = [
    {
      id: '1',
      source: full01,
      thumbnail: thumb01,
      caption: 'SGRed',
      description: 'iOS and Android mobile applications',
    },
    {
      id: '2',
      source: full02,
      thumbnail: thumb02,
      caption: 'ioBuilders',
      description: 'Corporate Web',
    },
    {
      id: '3',
      source: full03,
      thumbnail: thumb03,
      caption: 'MeteoAran',
      description: 'iOS and Android mobile applications',
    },
    {
      id: '4',
      source: full04,
      thumbnail: thumb04,
      caption: 'BoatTrack',
      description: 'iOS and Android mobile applications',
    },
    {
      id: '5',
      source: full05,
      thumbnail: thumb05,
      caption: 'BcnByBike',
      description: 'iOS and Android mobile applications',
    },
    {
      id: '6',
      source: full06,
      thumbnail: thumb06,
      caption: 'JuliaVilaJewels',
      description: 'Web E-commerce',
    }
]