import React from 'react'
import Helmet from 'react-helmet'

import Gallery from '../components/Gallery'
import Layout from '../components/layout'

const HomeIndex = () => {
  const siteTitle = 'Osledy Bazó - Full Stack Developer'
  const siteDescription = 'Osledy Bazó - Full Stack Developer'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <p>
            Hi I’m Osledy Bazó, Lead Developer at Hakkasan Group, I love working with Ruby/Rails and ReactJS/ React Native. In the past I have been involved in building projects related to ticketing systems, urban transportation, QR code generation and scanning, Boat sensors, and all kinds of applications…
          </p>
        </section>
        <section id="one1">
          <header className="major">
            <h2>Experience</h2>
          </header>

          <h4>Lead Developer - Hakkasan Group - Remote</h4>
          <p>
            2012 - Present<br/>
            Add, Deploy and Maintain features to a series of websites that are part of the Hakkasan Group, that is a global hospitality company committed to creating world-class experiences through excellence in service, design, and innovation. We work with Docker, Angular, Rails, Chef, Git, TDD, etc
          </p>

          <h4>Senior Software Engineer - Bouncing Shield - Remote</h4>
          <p>
            2008 - Present<br/>
            Work with Ruby/Rails and new tech stack
          </p>

          <h4>Web Developer - Bakedweb - Remote</h4>
          <p>
            2012 - 2014<br/>
            During my time at Bakedweb, I mainly worked with ruby and rails apps: Shopify sites, Refinery cms, Radiant cms, custom build apps with Padrino and Sinatra. I helped to develop and maintain tiesto.com website, todobebe.com and a lot of small websites/landing pages for selling vacation packages.
          </p>

        </section>

        <section id="two">
          <h2>Recent Work</h2>

          <Gallery />

        </section>

        <section id="three">
          <h2>Get In Touch</h2>
          <p>
            Email: <a href="mailto:osledybazo@gmail.com">osledybazo@gmail.com</a><br/>
            LinkedIn: <a target="_blank" href="https://linkedin.com/in/osledy-bazo">https://linkedin.com/in/osledy-bazo</a> <br/>
            Github: <a target="_blank" href="https://github.com/uokesita">https://github.com/uokesita</a> <br/>
            Twitter: <a target="_blank" href="https://twitter.com/@uokesita">https://twitter.com/@uokesita</a> <br/>
            Phone: +34665239201 <br/>
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
